import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "@lib/utils";
import { formSubmitRewrap } from "@utils/index";
import { trpc } from "@utils/trpc";
import { useSession } from "next-auth/react";
import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/AlertDialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { Input, input } from "./ui/Input";
import { Button } from "./ui/Button";
import type { Dispatch, SetStateAction } from "react";
import { posthog } from "posthog-js";

function useFormAndTranslations() {
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  const { data: session } = useSession();
  const formSchema = z.object({
    name: z.string().min(1, { message: t("nameErrorMessage") }),
    phone: z.string().min(1, { message: t("phoneErrorMessage") }),
    description: z.string().min(1, { message: t("descriptionErrorMessage") }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: session?.user?.name || "",
    },
  });

  return { form, formSchema };
}
type SendOnboardingLinkProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};
export default function SendOnboardingLink({
  setIsOpen,
}: SendOnboardingLinkProps) {
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, formSchema } = useFormAndTranslations();
  const sendInternalEmail = trpc.collaborators.sendInternalEmail.useMutation();

  async function onSubmit(data: z.infer<typeof formSchema>) {
    posthog.capture("partnership_requestes", {
      userPhone: data.phone,
      userName: data.name,
    });
    await sendInternalEmail.mutateAsync(data);
    setIsOpen(false);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          void toast.promise(formSubmitRewrap(form, onSubmit)(e), {
            loading: t("sendingEmail"),
            success: t("becomeCollaborator"),
            error: t("sendEmailError"),
          });
        }}
      >
        <AlertDialogHeader className="mb-2">
          <AlertDialogTitle>{t("modalTitle")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("modalDescription")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="mb-2 space-y-2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("name")}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("phone")}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("description")}</FormLabel>
                <FormControl>
                  <textarea
                    cols={6}
                    className={cn(
                      input(),
                      "focus:border-none focus:outline-current focus:ring-transparent",
                      "h-14"
                    )}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("cancel")}</AlertDialogCancel>
          <Button
            className="flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-medium"
            type="submit"
          >
            {t("continue")}
          </Button>
        </AlertDialogFooter>
      </form>
    </Form>
  );
}
