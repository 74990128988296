import { useTranslations } from "next-intl";
import { useState } from "react";
import { Button } from "./Button";
import SendOnboardingLink from "./SendOnboardingLink";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "./ui/AlertDialog";
import { BankingInformationsForm } from "./BankingInformationsForm";

export function CollaboratorButton(props: { onboardingStatus?: string }) {
  const { onboardingStatus } = props;
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button className="self-center">
          {onboardingStatus === "not_started" || onboardingStatus === undefined
            ? t("becomeCollaborator")
            : t("continueOnboarding")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        {onboardingStatus === "pending" ? (
          <BankingInformationsForm setIsOpen={setIsOpen} />
        ) : (
          <SendOnboardingLink setIsOpen={setIsOpen} />
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}
