import { zodResolver } from "@hookform/resolvers/zod";
import { formSubmitRewrap } from "@utils/index";
import { trpc } from "@utils/trpc";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import type { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/AlertDialog";
import { Button } from "./ui/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { Input } from "./ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import { posthog } from "posthog-js";
import { useSession } from "next-auth/react";

const BANKING_TYPE_MAP = {
  PIX: "Pix",
} as const;

function useFormAndTranslations() {
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  const formSchema = z.object({
    bankingType: z.enum(["PIX"]),
    pixKey: z.string().min(1, { message: t("phoneErrorMessage") }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bankingType: "PIX",
      pixKey: "",
    },
  });

  return { form, formSchema };
}
type BankingInformationsFormProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};
export function BankingInformationsForm({
  setIsOpen,
}: BankingInformationsFormProps) {
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, formSchema } = useFormAndTranslations();
  const createBankingInformation =
    trpc.collaborators.createBankingInformation.useMutation();
  const router = useRouter();

  const { data: session } = useSession();

  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      await createBankingInformation.mutateAsync(data);
      setIsOpen(false);
      if (session?.address) {
        posthog.capture("onboarding_completed", {
          userAddress: session?.address,
        });
      }
      router.reload();
    } catch {
      toast.error(t("bankingInfoError"));
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          void toast.promise(formSubmitRewrap(form, onSubmit)(e), {
            loading: t("savingBankingInfo"),
            success: t("bankingInfoSaved"),
            error: t("bankingInfoError"),
          });
        }}
      >
        <AlertDialogHeader className="mb-2">
          <AlertDialogTitle>{t("bankingInfoModalTitle")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("bankingInfoModalDescription")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="mb-2 space-y-2">
          <FormField
            control={form.control}
            name="bankingType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("bankingType")}</FormLabel>
                <FormControl>
                  <Select
                    value={field.value}
                    onValueChange={(e) => field.onChange(e)}
                  >
                    <SelectTrigger className="bg-input text-foreground">
                      <SelectValue placeholder={t("pickBankingType")} />
                    </SelectTrigger>
                    <SelectContent position="popper">
                      {Object.keys(BANKING_TYPE_MAP).map((key, index) => (
                        <SelectItem
                          key={`${key}-${index}`}
                          value={key as keyof typeof BANKING_TYPE_MAP}
                          className="text-foreground"
                        >
                          {
                            BANKING_TYPE_MAP[
                              key as keyof typeof BANKING_TYPE_MAP
                            ]
                          }
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="pixKey"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("pixKey")}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("cancel")}</AlertDialogCancel>
          <Button
            className="flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-medium"
            type="submit"
          >
            {t("continue")}
          </Button>
        </AlertDialogFooter>
      </form>
    </Form>
  );
}
